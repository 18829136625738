<template>
    <div>
        <headbox></headbox>
      <!-- 滑块组件 -->
      <banner positionSid="9183"></banner>
        <!--关于我们-->
        <div class="uswrap">
            <div class="uscontent">
                <div class="ustitle">联系我们</div>
                <!--              <div class="ustitle2">CONTACT US</div>-->
            </div>
        </div>

        <!-- 申请流程  合作流程-->
      <flowListTemplate></flowListTemplate>

        <!-- 地图-->
        <div id="bmap" ref="bmap" class="map">
<!--                <script type="text/javascript" src="//api.map.baidu.com/api?v=2.0&ak=D8k6mRX0PpNROcp6joNTmkVeCiZozQlC&s=1"></script>-->
<!--                <script type="text/javascript" src="./style/js/map.js"></script>-->
<!--                <script type="text/javascript">-->
<!--                baiduMap('map','119.96781','30.277554','杭州延广科技有限公司','杭州市余杭区余杭街道科技大道31号');-->
<!--            </script>-->
        </div>

        <!-- 侧边导航 -->
        <sidebar ref="mysiderbar"></sidebar>
        <foot></foot>
    </div>
</template>

<script>
import Swiper from 'swiper'
import wow from 'wowjs'
import BaiduMap from '@/utils/mapLoader.js'
import foot from '@/components/foot'
import headbox from "@/components/headfloat";
import banner from "@/components/banner";
import { yqjsapi , getprojectspi , ryznapi , yqhlapi , indexserviceapi , swiperapi } from '@/api/index.js';
import sidebar from "@/components/sidebar";
import { staticurl } from "@/utils/baseurl";
import { saveSession } from "@/utils/windowStorage";
import flowListTemplate from "@/components/flowListTemplate";
export default {
    components: {
      flowListTemplate,
      banner,
        headbox,
        foot,
        sidebar,
    },
    name: 'BMap',
    data() {
        return {
            map: null,
            ryzn: {
                title:'合作流程',
                list: [{
                    guide_Name: "园区账号注册",
                    guide_Content: "点击网页右上角注册按钮,填写真实信息完成登记。",
                },{
                    guide_Name: "园区账号注册",
                    guide_Content: "点击网页右上角注册按钮,填写真实信息完成登记。",
                },{
                    guide_Name: "园区账号注册",
                    guide_Content: "点击网页右上角注册按钮,填写真实信息完成登记。",
                }],
            },
          swiper: {
            imgs:
                [
                    // { imgurl: "../assets/images/newswiper.jpg" }
                    ],
          },
            projectInfo:{
                lng:0,
                lat:0,
                companyName:'',
                address:'',
            }

        }
    },
    created() {

    },
    destroyed() {

    },
    methods:{
        initMap() {

            var that=this
            setTimeout(() => {
                BaiduMap.init('D8k6mRX0PpNROcp6joNTmkVeCiZozQlC').then((BMap) => {
                    console.log('回调', BMap);
                    const map = new BMap.Map(this.$refs.bmap); // 第一步初始化 插入脚本
                   // console.log(map);
                   // map.centerAndZoom(new BMap.Point(119.96781,30.277554), 17); // 初始化地图,设置中心点坐标和地图级别
                    // 第二步 设置 120.158295,30.285484
                    // BaiduMap.baiduMap2(this.$refs.bmap,'120.158295','30.285484','杭州盼云跨境科技有限公司','浙江省杭州市拱墅区立新大厦1316室');
                    BaiduMap.baiduMap2(that.$refs.bmap,that.projectInfo.lng,that.projectInfo.lat,that.projectInfo.companyName,that.projectInfo.address);

                });


            });

},
            // hideChat(e){
            //     this.$refs.mysiderbar.hideChat()
            // },
        },

  mounted(){
    var swiper = new Swiper('.swiper-container', {
        direction: 'vertical',
        // slidesPerView: 1,
        // spaceBetween: 30,
        mousewheel: true,
        mousewheelControl : true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
    });
      // 页面增加全局点击
      window.addEventListener('click', this.hideChat)

      // this.initMap()

  },
    beforeDestroy() {
        /* 组件销毁的时候要移除侦听器 */
        window.removeEventListener('click', this.hideChat)
    },
    beforeMount() {

        let projectid = this.$store.state.project.projectid;

        // 查询公司信息
        getprojectspi({ apartment_Sid: projectid }).then((res) => {
            this.projectInfo.companyName = res.data.result[0].account_Name;
            this.projectInfo.address = res.data.result[0].address;
            this.projectInfo.lng = res.data.result[0].lng;
            this.projectInfo.lat =  res.data.result[0].lat;

            this.initMap()
        });

        // 入园指南
        getprojectspi({apartment_Sid : projectid})
      //合作流程
        // ryznapi({apartment_Sid : projectid}).then((res) => {
        //     this.ryzn.list = res.data.result;
        // })

      //banner
      // swiperapi({ apartment_Sid: projectid, position_Sid: "9183" }).then((res) => {
      //   this.swiper.imgs = res.data.result.map((item) => {
      //     item.imgurl = staticurl + item.picture;
      //     return item;
      //   });
      // });

    },
}
</script>

<style scoped>

    /*关于我们*/
    .uswrap{
        width: 100%;
        background-image: url("../assets/images/A.png");
        background-repeat: no-repeat;
        background-size:auto 60%;
        background-position: 99% center;
    }

    .uscontent{
        width: 100%;
        margin: 0 auto;
        margin-top: 35px;
    }

    .ustitle{
        text-align: center;
        font-size: 30px;
        /*color: rgba(93, 46, 140, 1);*/
        /*line-height: 60px;*/
        color:white;
        line-height: 100px;
        background-image: url("../assets/images/titleback.png");
        background-size: cover;
    }

    .ustitle2{
        font-size: 15px;
        color: rgba(93, 46, 140, 1);
        text-align: center;
    }

    .us_item{
        display: flex;
        align-items: center;
        margin-top: 30px;
    }

    .usimg{
        width: 50%;
    }

    .ustext{
        line-height: 30px;
        box-sizing: border-box;
        padding: 30px 40px;
        font-size: 14px;
    }

    .ustext1,.ustext2{
        letter-spacing: 1px;
        text-indent: 30px;
    }


    .map {
        /*display: block;*/
        width: 100%;
        height: 35.27752rem;
        background-color: #fafafa
    }

    @media screen and (max-width: 32.4375em) {
        .map {
            height: 17.63876rem
        }
    }

    @media (max-width: 600px){
      .uscontent{
        margin-top: 30px;
      }
    }
</style>
