export default {
    init: function (ak) {

            return new Promise(((resolve, reject) => {
                // if (!window.BMap) {
                    const script = document.createElement('script');
                    script.type = 'text/javascript';
                    script.src = `https://api.map.baidu.com/api?v=2.0&ak=${ak}&callback=init`;
                    script.onerror = reject;
                    document.head.appendChild(script);
                    console.log('window.BMap', window.BMap);
                    setTimeout(() => {
                        resolve(window.BMap);
                    }, 1000); // 这里延迟1秒是关键，要不显示不出来
                // }
            }));

    },
    baiduMap2:function(contentId,pointX,pointY,mapTitle,mapContent){
        function initMap(){
            createMap();
            addMarker();
        }

        function createMap(){
            var map = new BMap.Map(contentId);
            var point = new BMap.Point(pointX,pointY);
            map.centerAndZoom(point,17);
            window.map = map;
        }

        function setMapEvent(){
            map.enableDragging();
            map.enableScrollWheelZoom();
            map.enableDoubleClickZoom();
            map.enableKeyboard();
        }

        function addMapControl(){
            var ctrl_nav = new BMap.NavigationControl({anchor:BMAP_ANCHOR_TOP_LEFT,type:BMAP_NAVIGATION_CONTROL_LARGE});
            map.addControl(ctrl_nav);
            var ctrl_ove = new BMap.OverviewMapControl({anchor:BMAP_ANCHOR_BOTTOM_RIGHT,isOpen:1});
            map.addControl(ctrl_ove);
            var ctrl_sca = new BMap.ScaleControl({anchor:BMAP_ANCHOR_BOTTOM_LEFT});
            map.addControl(ctrl_sca);
        }

        var markerArr = [{title:mapTitle,content:mapContent,point:pointX+'|'+pointY,isOpen:0,icon:{w:23,h:25,l:46,t:21,x:9,lb:12}}];

        //alert(markerArr[0].point);
        function addMarker(){
            for(var i=0;i<markerArr.length;i++){
                var json = markerArr[i];
                var p0 = json.point.split("|")[0];
                var p1 = json.point.split("|")[1];
                var point = new BMap.Point(p0,p1);
                var iconImg = createIcon(json.icon);
                var marker = new BMap.Marker(point,{icon:iconImg});
                var iw = createInfoWindow(i);
                var label = new BMap.Label(json.title,{"offset":new BMap.Size(json.icon.lb-json.icon.x+10,-20)});
                marker.setLabel(label);
                map.addOverlay(marker);
                label.setStyle({
                    borderColor:"#808080",
                    color:"#333",
                    cursor:"pointer"
                });

                (function(){
                    var index = i;
                    var _iw = createInfoWindow(i);
                    var _marker = marker;
                    _marker.addEventListener("click",function(){
                        this.openInfoWindow(_iw);
                    });
                    _iw.addEventListener("open",function(){
                        _marker.getLabel().hide();
                    })
                    _iw.addEventListener("close",function(){
                        _marker.getLabel().show();
                    })
                    label.addEventListener("click",function(){
                        _marker.openInfoWindow(_iw);
                    })
                    if(!!json.isOpen){
                        label.hide();
                        _marker.openInfoWindow(_iw);
                    }
                })()
            }
        }
        function createInfoWindow(i){
            var json = markerArr[i];
            var iw = new BMap.InfoWindow("<b class='iw_poi_title' title='" + json.title + "'>" + json.title + "</b><div class='iw_poi_content'>"+json.content+"</div>");
            return iw;
        }
        function createIcon(json){
            var icon = new BMap.Icon("http://app.baidu.com/map/images/us_mk_icon.png", new BMap.Size(json.w,json.h),{imageOffset: new BMap.Size(-json.l,-json.t),infoWindowOffset:new BMap.Size(json.lb+5,1),offset:new BMap.Size(json.x,json.h)})
            return icon;
        }
        initMap();
    }
}
